import React, { createContext } from 'react'
import useProvideCustomer from 'hooks/customer/useProvideCustomer'

const customerContext = createContext()

const ProvideCustomer = ({ children }) => {
  const customer = useProvideCustomer()

  return (
    <customerContext.Provider value={customer}>
      {children}
    </customerContext.Provider>
  )
}

export { customerContext, ProvideCustomer }
