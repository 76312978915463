import sanitize from 'sanitize-html'

export const getKey = (key) => {
  // generate a local storage key based on value and session id
  let sessionId = sessionStorage.getItem('sessionId')
  if (!sessionId) {
    const urlParams = new URLSearchParams(window.location.search)
    sessionId = sanitize(urlParams.get('sessionId'))
    sessionStorage.setItem('sessionId', sessionId)
  }

  if (!sessionId) return key

  return `${sessionId}:${key}`
}
