import i18n from 'locales/i18n'

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }

export const formatDate = (date) =>
  date.toLocaleDateString(i18n.language, dateOptions)

export const formatPrice = (val, currency) => {
  if (currency) {
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
    }).format(Number(val))
  }
}

export const formatDateForAPI = (date) => {
  if (!date) return undefined
  const d = new Date(date)
  if (!d || d.toString() === 'Invalid Date') return undefined
  let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
  let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  return `${year}-${month}-${day}`
}
