import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 100;
`

export const OverlayLoader = () => (
  <Wrapper>
    <CircularProgress color="primary" />
  </Wrapper>
)
