import React, { createContext } from 'react'
import useProvideNotify from 'hooks/notify/useProvideNotify'
import Notification from 'components/organisms/notification'

const notifyContext = createContext()

const ProvideNotify = ({ children }) => {
  const notify = useProvideNotify()

  return (
    <notifyContext.Provider value={notify}>
      {children}
      <Notification />
    </notifyContext.Provider>
  )
}

export { notifyContext, ProvideNotify }
