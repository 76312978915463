import React, { createContext } from 'react'
import useProvideLanguage from 'hooks/language/useProvideLanguage'

const LanguageContext = createContext()

const ProvideLanguage = ({ children }) => {
  const data = useProvideLanguage()

  return (
    <LanguageContext.Provider value={data}>{children}</LanguageContext.Provider>
  )
}

export { LanguageContext, ProvideLanguage }
