const sources = {
  midlayer: {
    uri: {
      'checkout.fitforme.com': process.env.REACT_APP_MIDLAYER_ENTRYPOINT,
      'checkout-tst.fitformedev.nl':
        process.env.REACT_APP_MIDLAYER_ENTRYPOINT_STA,
      'myffm-local.fitformedev.nl':
        process.env.REACT_APP_MIDLAYER_ENTRYPOINT_STA,
    },
  },
}

export default sources
