import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { DateTime } from 'luxon';

const locizeOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    referenceLng: process.env.REACT_APP_LOCIZE_REFERENCE_LNG,
    version: process.env.REACT_APP_LOCIZE_VERSION
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (value instanceof Date) {
                    return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
                }
                return value;
            }
        },
        backend: locizeOptions,
        saveMissing: false
    });

export default i18n;
