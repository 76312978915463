import React, { createContext } from 'react'
import useProvideCheckoutInfo from 'hooks/checkoutInfo/useProvideCheckoutInfo'

const checkoutInfoContext = createContext()

const ProvideCheckoutInfo = ({ children }) => {
  const checkoutInfo = useProvideCheckoutInfo()

  return (
    <checkoutInfoContext.Provider value={checkoutInfo}>
      {children}
    </checkoutInfoContext.Provider>
  )
}

export { checkoutInfoContext, ProvideCheckoutInfo }
