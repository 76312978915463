import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useNotify from '../notify/useNotify'
import request from './request'
import { trackError } from 'helpers/trackError'

const defaultBody = {}

export const getErrorMessage = (error) => {
  const { response, message } = error
  if (response) {
    const { data, status } = response
    return data?.message || data?.title || status || 'unknown'
  } else {
    return message || 'no response'
  }
}

export const useRequestErrorHandler = () => {
  const { t } = useTranslation()
  const { openNotification } = useNotify()

  const requestErrorHandler = useCallback(
    (error) => {
      const message = getErrorMessage(error)
      trackError(`Request error: ${message}`)
      if (error.response) {
        console.log(' ')
        console.log('//////// server responded with error: start /////////')
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        console.log('//////// server responded with error: end /////////')
        console.log(' ')

        return openNotification({
          type: 'error',
          message: t('error.server', { message }),
        })
      } else {
        if (error.request) {
          console.log('Got no response for request: ', error.request)
        }

        console.log('Something went wrong with request. Error: ', error.message)

        if (error.message === 'Network Error') {
          return openNotification({
            type: 'error',
            message: t('error.network'),
          })
        }
      }

      return openNotification({
        type: 'error',
        message: t('error.something-went-wrong'),
      })
    },
    [openNotification, t]
  )

  return { requestErrorHandler }
}

const useRequest = ({
  url,
  method,
  body = defaultBody,
  onSuccess,
  onError,
}) => {
  const [loading, setLoading] = useState(false)
  const [isFetched, setIsFetched] = useState(false)

  const { requestErrorHandler } = useRequestErrorHandler()

  const doRequest = useCallback(
    async (props = {}) => {
      if (!url) return
      const requestBody = { ...body, ...props }

      try {
        setLoading(true)

        const response = await request[method](
          url,
          method === 'get' ? { params: requestBody } : requestBody
        )

        if (onSuccess) {
          onSuccess(response.data)
        }

        setIsFetched(true)
        setLoading(false)

        return response.data
      } catch (err) {
        setIsFetched(true)
        setLoading(false)

        if (onError) {
          return onError(err, requestBody)
        }
        return requestErrorHandler(err)
      }
    },
    [url, method, body, onSuccess, onError, requestErrorHandler]
  )

  return { doRequest, loading, isFetched }
}

export default useRequest
