import React, { createContext } from 'react'
import useProvideSubscriptions from 'hooks/subscriptions/useProvideSubscriptions'

const subscriptionsContext = createContext()

const ProvideSubscriptions = ({ children }) => {
  const subscriptions = useProvideSubscriptions()

  return (
    <subscriptionsContext.Provider value={subscriptions}>
      {children}
    </subscriptionsContext.Provider>
  )
}

export { subscriptionsContext, ProvideSubscriptions }
