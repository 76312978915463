export const getAddonsFromSubscription = (subscription) => {
  if (!subscription) return []
  const addons = {}

  subscription.items.forEach((item) => {
    if (!item.quantity) return

    if (item.products) {
      item.products.forEach((product) => {
        const productQuantity = item.quantity * (product.quantity || 1)

        addons[product.chargeBeeId] = {
          id: product.chargeBeeId,
          unitPrice: Math.floor(Number(product.priceWithDiscount) * 100),
          quantity:
            (addons[product.chargeBeeId]?.quantity || 0) + productQuantity,
        }
      })
    } else {
      addons[item.chargeBeeId] = {
        id: item.chargeBeeId,
        unitPrice: Math.floor(Number(item.priceWithDiscount) * 100),
        quantity: (addons[item.chargeBeeId]?.quantity || 0) + item.quantity,
      }
    }
  })

  return Object.values(addons)
}
