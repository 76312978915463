import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage'

const useProvideLanguage = () => {
  const { i18n } = useTranslation()
  const { session } = useCheckoutInfo()
  const [lang, _setLang] = useSessionStorage('lang', 'en')
  const [listOfLang, setListOfLang] = useState({})
  const [initialLangHandled, setInitialLangHandled] = useSessionStorage(
    'initialLangHandled',
    false
  )

  const setLang = useCallback(
    (newLang) => {
      _setLang(newLang)
      document.documentElement.lang = newLang
    },
    [_setLang]
  )

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (lang !== document.documentElement.lang) {
      setLang(lang)
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!session?.data) return
    if (initialLangHandled) return
    if (Object.keys(listOfLang).length === 0) return
    // set initial lanugage from session
    const sessionLanguage = session.data.lang
    if (listOfLang[sessionLanguage]) {
      setLang(sessionLanguage)
    } else {
      setLang('en')
    }
    setInitialLangHandled(true)
  }, [setLang, initialLangHandled, setInitialLangHandled, session, listOfLang])

  useEffect(() => {
    // update i18n language after change in switcher
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang)
    }
  }, [i18n, lang])

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages((err, ret) => {
      if (err) return
      setListOfLang(ret)
    })
  }, [i18n])

  return { listOfLang, lang, setLang }
}

export default useProvideLanguage
