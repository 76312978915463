import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage'
import { useState } from 'react'

const useProvideCustomer = () => {
  const [personalDetails, setPersonalDetails] = useState({})
  const [shippingAddress, setShippingAddress] = useState({})
  const [billingAddress, setBillingAddress] = useState({})
  const [shippingIsBilling, setShippingIsBilling] = useSessionStorage(
    'shippingIsBilling',
    false
  )
  const [agreeWithTerms, setAgreeWithTerms] = useSessionStorage(
    'agreeWithTerms',
    false
  )
  const [customerCBId, setCustomerCBId] = useSessionStorage('customerCBId', '')
  const [accountId, setAccountId] = useSessionStorage('accountId', '')
  const [paymentFlow, setPaymentFlow] = useSessionStorage('paymentFlow', '')
  const [customerMidlayerId, setCustomerMidlayerId] = useSessionStorage(
    'customerMidlayerId',
    null
  )

  return {
    personalDetails,
    setPersonalDetails,
    shippingAddress,
    setShippingAddress,
    billingAddress,
    setBillingAddress,
    shippingIsBilling,
    setShippingIsBilling,
    agreeWithTerms,
    setAgreeWithTerms,
    customerCBId,
    setCustomerCBId,
    accountId,
    setAccountId,
    paymentFlow,
    setPaymentFlow,
    customerMidlayerId,
    setCustomerMidlayerId,
  }
}

export default useProvideCustomer
