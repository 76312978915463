import sanitize from 'sanitize-html'
import { getKey } from './localStorage'

const getGoogleAnalyticsClientId = () => {
  try {
    const tracker = window.ga.getAll()[0]
    return tracker.get('clientId')
  } catch (e) {
    return 'N/A'
  }
}

export const sendEvent = (event) => {
  const urlParams = new URLSearchParams(window.location.search)
  const sessionId = sanitize(urlParams.get('sessionId'))
  const coupon = localStorage.getItem(getKey('validatedPromocode'))
  const currency = localStorage.getItem(getKey('currency'))
  const storedSubs = localStorage.getItem(getKey('subscriptions'))
  const utmMedium = localStorage.getItem(getKey('utmMedium'))
  const store = localStorage.getItem(getKey('store'))
  const subscriptions = storedSubs ? JSON.parse(storedSubs) : []

  const ecommerceItems = []
  const regularItems = []
  let value = 0

  subscriptions.forEach(({ items }) => {
    items.forEach((item) => {
      if (item.bundle) {
        item.products.forEach((product) => {
          value += Number(product.priceWithDiscount)
          ecommerceItems.push({
            id: product.chargeBeeId,
            name: product.name,
            sku: null,
            category: product.productName,
            price: product.priceWithDiscount,
            stocklevel: null,
            quantity: product.quantity,
            coupon,
          })

          regularItems.push({
            item_id: product.chargeBeeId,
            item_name: product.name,
            item_brand: product.productName,
            price: product.priceWithDiscount,
            item_category: product.productName,
            quantity: product.quantity,
            google_business_vertical: 'retail',
            id: product.chargeBeeId,
            coupon,
          })
        })
      } else {
        value += Number(item.priceWithDiscount)
        ecommerceItems.push({
          id: item.chargeBeeId,
          name: item.name,
          sku: null,
          category: item.productName,
          price: item.priceWithDiscount,
          stocklevel: null,
          quantity: item.quantity,
          coupon,
        })

        regularItems.push({
          item_id: item.chargeBeeId,
          item_name: item.name,
          item_brand: item.productName,
          price: item.priceWithDiscount,
          item_category: item.productName,
          quantity: item.quantity,
          google_business_vertical: 'retail',
          id: item.chargeBeeId,
          coupon,
        })
      }
    })
  })

  const firstSubscription = subscriptions.length ? subscriptions[0] : {}

  const eventBody = {
    event,
    plan_type: firstSubscription.interval,
    checkout_medium: utmMedium,
    clientId: getGoogleAnalyticsClientId(),
    checkoutId: sessionId,
    store,
    ecommerce: { checkout: ecommerceItems },
    currency,
    value,
    items: regularItems,
  }

  console.log('sending event: ', JSON.stringify(eventBody, null, 2))
  window.dataLayer?.push(eventBody)
}
