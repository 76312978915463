import React from 'react'
import { Slide, Alert, Snackbar } from '@mui/material'
import useNotify from 'hooks/notify/useNotify'

export const NOTIFY_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
}

const Notification = () => {
  const { closeNotification, show, type, message } = useNotify()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    closeNotification()
  }

  return (
    !!show && (
      <Snackbar
        open
        disableWindowBlurListener
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={(props) => <Slide {...props} />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleClose}
          severity={type || 'error'}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    )
  )
}

export default Notification
