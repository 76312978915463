import axios from 'axios'

import sources from './sources'

const getUri = (source, action, fallback) => {
  return !sources[source]
    ? undefined
    : sources[source]['uri'][action] || sources[source]['uri'][fallback]
}

export const BASE_URL = getUri(
  'midlayer',
  window.location.hostname,
  'admin-tst.fitformedev.nl'
)

export const BASE_API_URL = `${BASE_URL}/api`

let request = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
})

request.interceptors.response.use(
  async (config) => {
    return config
  },
  async (error) => {
    return Promise.reject(error)
  }
)

export default request
