import { token } from 'hooks/request/access_token'
import { useCallback, useEffect, useState } from 'react'
import * as sanitize from 'sanitize-html'
import useRequest, { useRequestErrorHandler } from 'hooks/request/useRequest'
import useNotify from 'hooks/notify/useNotify'
import { useTranslation } from 'react-i18next'
import useCustomer from 'hooks/customer/useCustomer'
import useGlobalLoader from 'hooks/globalLoader/useGlobalLoader'
import { useSessionStorage } from 'hooks/sessionStorage/useSessionStorage'
import { getKey } from 'helpers/localStorage'
import request from 'hooks/request/request'

const useProvideCheckoutInfo = () => {
  const { t } = useTranslation()
  const [sessionValuesHandled, setSessionValuesHandled] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const {
    setPersonalDetails,
    setBillingAddress,
    setShippingAddress,
    shippingAddress,
    billingAddress,
    personalDetails,
    setCustomerCBId,
    paymentFlow,
    setPaymentFlow,
    setCustomerMidlayerId,
  } = useCustomer()
  const [sessionFetched, setSessionFetched] = useState(false)
  const [sessionUpdating, setSessionUpdating] = useState(false)
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [session, setSession] = useState({})
  const [store, setStore] = useState({})
  const [chargeBeeWebsite, setChargeBeeWebsite] = useState('')
  const { openNotification } = useNotify()
  const [urlParsed, setUrlParsed] = useState(false)
  const [sessionShippingAddress, setSessionShippingAddress] = useState({})
  const [sessionBillingAddress, setSessionBillingAddress] = useState({})
  const [sessionPersonalDetails, setSessionPersonalDetails] = useState({})
  const [sessionId, setSessionId] = useState()
  const { setLoading } = useGlobalLoader()
  const [adyenMethods, setAdyenMethods] = useSessionStorage('adyenMethods')
  const [showAuth, setShowAuth] = useSessionStorage('showAuth', true)
  const { requestErrorHandler } = useRequestErrorHandler()

  const { doRequest: getPaymentMethods, loading: paymentMethodsLoading } =
    useRequest({
      url: `checkout/payment-methods?storeId=${store?.id}&token=${token}`,
      method: 'get',
      onSuccess: ({ paymentMethods }) => {
        setAdyenMethods({
          paymentMethods: paymentMethods.map((item) =>
            item.type === 'sepadirectdebit'
              ? { ...item, name: t('payment.sepa') }
              : item
          ),
        })
      },
    })

  const {
    doRequest: fetchStore,
    loading: storeLoading,
    isFetched: storeFetched,
  } = useRequest({
    url: `/checkout/store-info`,
    method: 'get',
  })

  const { doRequest: fetchSession, loading: sessionLoading } = useRequest({
    url: `/checkout-session`,
    method: 'get',
    onError: (err) => {
      console.error('error while fetching session: ', err)
      setLoading(false)
      setDataFetched(true)

      if (err.response?.status === 410) {
        setIsSessionExpired(true)
      } else {
        window.location.href = '/error'
      }
    },
  })

  useEffect(() => {
    if (store?.id && !paymentMethodsLoading && !adyenMethods) {
      getPaymentMethods()
    }
  }, [getPaymentMethods, adyenMethods, store, paymentMethodsLoading])

  useEffect(() => {
    if (!storeFetched || !sessionFetched) {
      return
    }
    if (sessionValuesHandled) {
      return
    }

    setBillingAddress(sessionBillingAddress)
    setShippingAddress(sessionShippingAddress)
    setPersonalDetails(sessionPersonalDetails)
    setSessionValuesHandled(true)
  }, [
    storeFetched,
    sessionFetched,
    sessionValuesHandled,
    sessionShippingAddress,
    sessionBillingAddress,
    sessionPersonalDetails,
    setBillingAddress,
    setShippingAddress,
    setPersonalDetails,
    setSessionValuesHandled,
  ])

  useEffect(() => {
    if (urlParsed) {
      return
    }
    const urlParams = new URLSearchParams(window.location.search)

    const paramSessionId = sanitize(urlParams.get('sessionId'))

    if (paramSessionId) {
      setSessionId(paramSessionId)
    } else if (window.location.pathname !== '/error') {
      window.location.href = '/error'
    }

    /*
      sessionSubscriptions structure:
      [{
        "startDate": "2022-03-24",
        "items": []
      }, {
        "startDate": null,
        "items": []
      }]


      items examples:
      [
        { //bundle
          "bundle":true,
            "id":1,
            "quantity":1,
            "products":[
               {
                  "mandatory": true,
                  "variationId":1,
                  "quantity":1
               },
               {
                  "variationId":3,
                  "quantity":1
               }
            ]
        },
        { //single product
          "bundle":false,
          "variationId":1,
          "quantity":2
          "mandatory": true,
        },
      ]
    */

    const error = sanitize(urlParams.get('error'))
    if (error !== 'null' && error !== '') {
      openNotification({
        type: 'error',
        message: error,
      })
      urlParams.delete('error')
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${urlParams.toString()}`
      )
    }

    setUrlParsed(true)
  }, [openNotification, urlParsed])

  useEffect(() => {
    if (sessionFetched || sessionLoading || !sessionId) return

    fetchSession({ sessionId }).then((response) => {
      setSessionFetched(true)
      if (!response) return

      // if (response.hasPayment) {
      //   // we assume that payment will be resolved eventally
      //   window.location.href = 'https://fitforme.com/thank-you'
      //   return
      // }
      setSession(response)
    })
  }, [
    chargeBeeWebsite,
    sessionId,
    fetchSession,
    openNotification,
    t,
    sessionFetched,
    sessionLoading,
  ])

  useEffect(() => {
    if (storeLoading || storeFetched || !chargeBeeWebsite) return
    fetchStore({ chargeBeeWebsite, token }).then((storeResponse) => {
      if (!storeResponse) throw Error('could not fetch store')
      setStore(storeResponse)

      if (personalDetails.marketingConsent === undefined) {
        setPersonalDetails((cur) => ({
          ...cur,
          marketingConsent: storeResponse.csMarketingCheckedByDefault,
        }))
      }
    })
  }, [
    chargeBeeWebsite,
    fetchStore,
    storeFetched,
    storeLoading,
    personalDetails,
    setPersonalDetails,
  ])

  useEffect(() => {
    if (!paymentFlow && store) {
      setPaymentFlow(store.paymentFlow)
    }
  }, [store, paymentFlow, setPaymentFlow])

  useEffect(() => {
    if (dataFetched) return

    if (storeFetched && sessionFetched) {
      setLoading(false)
      setDataFetched(true)
    } else if (window.location.pathname !== '/error') {
      setLoading(true)
    }
  }, [storeFetched, sessionFetched, dataFetched, setLoading])

  useEffect(() => {
    const { data, chargeBeeWebsite } = session

    if (data?.customerBillingAddress) {
      setSessionBillingAddress(data.customerBillingAddress)
    }

    if (data?.customerShippingAddress) {
      setSessionShippingAddress(data.customerShippingAddress)
    }

    if (data?.customerPersonalInfo) {
      setSessionPersonalDetails(data.customerPersonalInfo)
    }

    if (chargeBeeWebsite) {
      setChargeBeeWebsite(chargeBeeWebsite)
    }

    if (data?.customerCBId) {
      setCustomerCBId(data?.customerCBId)
      setCustomerMidlayerId(data?.customerMidlayerId)
      setShowAuth(false)
    }

    if (session?.data?.utm_medium === 'Baricol_migration') {
      setShowAuth(false)
    }
  }, [
    session,
    setShowAuth,
    setCustomerCBId,
    setPaymentFlow,
    setCustomerMidlayerId,
  ])

  const updateSession = useCallback(
    async (updatedData) => {
      setSessionUpdating(true)
      const initialData = {
        ...session.data,
        customerShippingAddress: shippingAddress,
        customerBillingAddress: billingAddress,
        customerPersonalInfo: personalDetails,
      }

      initialData.customerEmail =
        updatedData?.customerPersonalInfo?.email || personalDetails.email

      let result = null

      try {
        result = await request.put(`/checkout-session?sessionId=${sessionId}`, {
          ...initialData,
          ...updatedData,
        })
        setSessionFetched(false) // in order to refetch
      } catch (error) {
        requestErrorHandler(error)
      }

      setSessionUpdating(false)

      return result
    },
    [
      session,
      shippingAddress,
      billingAddress,
      personalDetails,
      sessionId,
      requestErrorHandler,
    ]
  )

  useEffect(() => {
    if (!session?.data) return
    localStorage.setItem(getKey('currency'), store?.currency || 'EUR')
    localStorage.setItem(
      getKey('utmMedium'),
      session?.data?.utm_medium || 'website'
    )
  }, [store?.currency, session?.data])

  return {
    store,
    sessionId,
    chargeBeeWebsite,
    sessionSubscriptions: session?.data?.subscriptions
      ? Object.values(session.data.subscriptions)
      : [],
    utmMedium: session?.data?.utm_medium || 'website',
    utmSource: session?.data?.utm_source || 'fitforme.com',
    productFlow: session?.productFlow,
    currency: store?.currency || 'EUR',
    session,
    updateSession,
    sessionUpdating,
    sessionValuesHandled,
    adyenMethods,
    showAuth,
    setShowAuth,
    isSessionExpired,
  }
}

export default useProvideCheckoutInfo
