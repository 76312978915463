import request from 'hooks/request/request'
import sanitize from 'sanitize-html'
import * as Sentry from '@sentry/react'

export const trackError = (message, paymentMethod = '') => {
  Sentry.captureException(message)

  let sessionId = sessionStorage.getItem('sessionId')
  if (!sessionId) {
    const urlParams = new URLSearchParams(window.location.search)
    sessionId = sanitize(urlParams.get('sessionId'))
    sessionStorage.setItem('sessionId', sessionId)
  }

  if (!sessionId) return

  if (message.length > 200) {
    message = message.substring(0, 200)
  }

  request.post(`/checkout/${sessionId}/payment-error`, {
    message,
    paymentMethod,
    code: 1,
  })
}
