import { useState, useEffect, useCallback } from 'react'
import useCheckoutInfo from 'hooks/checkoutInfo/useCheckoutInfo'
import { token } from 'hooks/request/access_token'
import useRequest from 'hooks/request/useRequest'
import { getAddonsFromSubscription } from 'components/organisms/subscriptions/utils'
import { debounce } from 'lodash'
import { formatDateForAPI } from 'helpers/format'
import useNotify from 'hooks/notify/useNotify'
import { useTranslation } from 'react-i18next'
import { sendEvent } from 'helpers/analytics'
import { getKey } from 'helpers/localStorage'
import useCustomer from 'hooks/customer/useCustomer'

const useProvideSubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([])
  const [totalBySubscriptionDate, setTotalBySubscriptionDate] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(0)
  const [totalPrice, setTotalPrice] = useState('...')
  const [validatedPromocode, setValidatedPromocode] = useState()
  const { store, sessionSubscriptions, billingAddress, session, productFlow } =
    useCheckoutInfo()
  const [initialCouponHandled, setInitialCouponHandled] = useState(false)
  const { openNotification } = useNotify()
  const { t } = useTranslation()
  const [pimcoreInfoFetched, setPimcoreInfoFetched] = useState(false)
  const { personalDetails, customerMidlayerId } = useCustomer()

  useEffect(() => {
    if (!session?.data) return
    if (initialCouponHandled) return
    setValidatedPromocode(session.data.coupon)
    setInitialCouponHandled(true)
  }, [session, validatedPromocode, initialCouponHandled])

  const {
    doRequest: fetchOrderData,
    isFetched: isChargebeeInfoFetched,
    loading: chargebeeInfoLoading,
  } = useRequest({
    url: `/checkout/order-data?token=${token}&storeId=${store?.id}`,
    method: 'post',
    onSuccess: (data) => setSubscriptions(data.subscriptions),
  })

  const { doRequest: fetchPimcoreInfo, loading: pimcoreInfoLoading } =
    useRequest({
      url: `api/checkout/${store?.id}/cart?token=${token}`,
      method: 'post',
      onSuccess: (data) => {
        setSubscriptions(data.subscriptions)
        setTotalPrice(data.total)
        setDiscountAmount(data.discount)

        const pricePerDate = {}

        data.subscriptions.forEach((subscription) => {
          const discount = subscription.discounts.reduce((a, b) => a + b, 0)
          const startDate = !subscription.startDate
            ? 'Immediately'
            : formatDateForAPI(new Date(subscription.startDate))
          if (!pricePerDate[startDate]) {
            pricePerDate[startDate] = { total: 0, discount: 0 }
          }

          const total = Number(subscription.total)
          pricePerDate[startDate].total += total
          pricePerDate[startDate].discount += discount
        })

        setTotalBySubscriptionDate(pricePerDate)
      },
    })

  const handleEstimationError = useCallback(() => {
    if (validatedPromocode) {
      setValidatedPromocode()
      openNotification({
        type: 'error',
        message: t('coupon-form.code-not-validated'),
      })
    } else {
      window.location.href = '/error'
    }
  }, [validatedPromocode, openNotification, t])

  const handleEstimationSuccess = useCallback(
    (response) => {
      const pricePerDate = {}

      response?.forEach(
        ({
          subscription_estimate,
          invoice_estimate,
          next_invoice_estimate,
        }) => {
          const timestamp = subscription_estimate.next_billing_at * 1000

          const startDate = invoice_estimate
            ? 'Immediately'
            : formatDateForAPI(new Date(timestamp))

          if (!pricePerDate[startDate]) {
            pricePerDate[startDate] = { total: 0, discount: 0 }
          }

          const estimate = invoice_estimate || next_invoice_estimate
          const total = Number(estimate.amount_due)
          const discount = Number(estimate.sub_total) - total
          pricePerDate[startDate].total += total
          pricePerDate[startDate].discount += discount
        }
      )

      setTotalBySubscriptionDate(pricePerDate)
    },
    [setTotalBySubscriptionDate]
  )

  const { doRequest: estimateTotalPrice, loading: priceLoading } = useRequest({
    url: `/checkout/subscription-estimate?storeId=${store?.id}&token=${token}&multiple=1`,
    method: 'post',
    onError: handleEstimationError,
    onSuccess: handleEstimationSuccess,
  })

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedEstimateTotalPrice = useCallback(
    debounce(estimateTotalPrice, 500),
    [estimateTotalPrice]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (
      !store?.id ||
      !sessionSubscriptions?.length ||
      isChargebeeInfoFetched ||
      chargebeeInfoLoading ||
      productFlow !== 'v1'
    ) {
      return
    }

    fetchOrderData(sessionSubscriptions)
  }, [
    store?.id,
    sessionSubscriptions,
    fetchOrderData,
    isChargebeeInfoFetched,
    chargebeeInfoLoading,
    productFlow,
  ])

  useEffect(() => {
    if (
      !store?.id ||
      !sessionSubscriptions?.length ||
      pimcoreInfoLoading ||
      productFlow !== 'v2' ||
      pimcoreInfoFetched
    ) {
      return
    }
    setTotalPrice('...')
    setPimcoreInfoFetched(true)

    fetchPimcoreInfo({
      subscriptions: sessionSubscriptions,
      customerPersonalInfo: {
        email: personalDetails?.email || '',
        firstName: personalDetails?.firstName || '',
        lastName: personalDetails?.lastName || '',
        cf_surgery_date: personalDetails?.surgeryDate || '',
        cf_surgery_type_v2: personalDetails?.surgeryType || '',
        cf_clinic_v2: personalDetails?.clinic || '',
        cf_surgeon_name: personalDetails?.surgeonName || '',
      },
      coupon: validatedPromocode,
      customerBillingAddress: billingAddress,
      customerMidlayerId,
    })
  }, [
    store?.id,
    sessionSubscriptions,
    productFlow,
    pimcoreInfoLoading,
    fetchPimcoreInfo,
    personalDetails,
    billingAddress,
    validatedPromocode,
    pimcoreInfoFetched,
    customerMidlayerId,
  ])

  useEffect(() => {
    setPimcoreInfoFetched(false)
  }, [validatedPromocode])

  useEffect(() => {
    if (productFlow !== 'v1') return
    const requestBody = subscriptions
      .map((subscription) => {
        const addons = getAddonsFromSubscription(subscription)
        const { plan, startDate } = subscription

        if (addons.length) {
          return {
            billingAddress,
            subscription: {
              planId: plan,
              startDate: startDate ? new Date(startDate) / 1000 : null,
            },
            addons,
            couponIds: validatedPromocode ? [validatedPromocode] : [],
          }
        }
        return null
      })
      .filter(Boolean)

    if (requestBody.length) {
      debouncedEstimateTotalPrice(requestBody)
    }
  }, [
    subscriptions,
    billingAddress,
    debouncedEstimateTotalPrice,
    validatedPromocode,
    setTotalBySubscriptionDate,
    productFlow,
  ])

  useEffect(() => {
    if (priceLoading) {
      setTotalPrice('...')
    }
  }, [priceLoading, setTotalPrice])

  useEffect(() => {
    if (productFlow !== 'v1') return

    if (totalBySubscriptionDate) {
      let total = 0
      let discount = 0

      Object.values(totalBySubscriptionDate).forEach((item) => {
        total += item.total
        discount += item.discount
      })

      setTotalPrice(total)
      setDiscountAmount(discount)
    }
  }, [totalBySubscriptionDate, setTotalPrice, setDiscountAmount, productFlow])

  useEffect(() => {
    if (!subscriptions.length) return
    localStorage.setItem(getKey('subscriptions'), JSON.stringify(subscriptions))
    localStorage.setItem(getKey('validatedPromocode'), validatedPromocode)
    localStorage.setItem(getKey('store'), store?.name)
  }, [validatedPromocode, subscriptions, store])

  useEffect(() => {
    // wait till subscriptions data fetched and processed
    setTimeout(() => {
      if (window.location.pathname !== '/thanks') {
        sendEvent('begin_checkout')
      }
    }, 2000)
  }, [])

  useEffect(() => {
    if (validatedPromocode) {
      // wait till subscriptions data fetched and processed
      setTimeout(() => sendEvent('add_coupon_code'), 2000)
    }
  }, [validatedPromocode])

  return {
    subscriptions,
    discountAmount,
    totalPrice,
    totalBySubscriptionDate,
    validatedPromocode,
    setValidatedPromocode,
  }
}

export default useProvideSubscriptions
