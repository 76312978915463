import { useCallback, useState } from 'react'
import { NOTIFY_TYPE } from 'components/organisms/notification'

const useProvideNotify = () => {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState(false)
  const [type, setType] = useState(false)

  const closeNotification = () => {
    setShow(false)
  }

  const openNotification = useCallback(({ type, message, messageKey }) => {
    setType(type || NOTIFY_TYPE.info)
    setMessage(message || messageKey)
    setShow(true)
  }, [])

  return {
    show,
    type,
    message,
    closeNotification,
    openNotification,
  }
}

export default useProvideNotify
