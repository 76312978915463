import React, { createContext, useState, useCallback } from 'react'
import { debounce } from 'lodash'
import { OverlayLoader } from 'components/atoms/overlay-loader'

const GlobalLoaderContext = createContext()

const ProvideGlobalLoader = ({ children }) => {
  const [loading, _setLoading] = useState(false)

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedStopLoader = useCallback(
    debounce(() => _setLoading(false), 1000),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const setLoading = (val) => {
    if (val) {
      _setLoading(true)
      debouncedStopLoader.cancel()
    } else {
      debouncedStopLoader()
    }
  }

  return (
    <GlobalLoaderContext.Provider value={{ setLoading, loading }}>
      {children}
      {!!loading && <OverlayLoader />}
    </GlobalLoaderContext.Provider>
  )
}

export { GlobalLoaderContext, ProvideGlobalLoader }
